//import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import ko from 'knockout';

export default class login extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});
        if (Store.getState().auth.value)
            this.Router.navigate('/');

        this.phone = ko.observable('');
        this.password = ko.observable('');
        this.errorPhone = ko.observable('');
        this.errorMessage = ko.observable('');
        this.errorPassword = ko.observable('');
        this.validatePhone = ko.observable(false);
        this.showPassword = ko.observable(false);

        // console.log(Store.getState().auth.value, Router.navigate(''), Server);

        if (Store.getState().auth.value){
            this.Router.navigate('/');
        }

    }

    EventPassword() {
        return {
            keyup: function (d, event) {
                this.password(event.currentTarget.value);
            }
        };
    }

    validatePhone(phone) {
        const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
        return re.test(phone);
    }

    eventField(fieldName) {
        return {
            keyup: (data, event) => {
                let value = event.target.value;
                if (fieldName === 'phone') {
                    if (document.querySelector('#phone').classList.contains('reduce') &&  this.validatePhone(value)) {
                        document.querySelector('#phone').classList.remove('reduce');
                    }
                    if (event.code === 'Enter') {
                        document.querySelector('#password').focus();
                    }
                }
                if (fieldName === 'password') {
                    if (document.querySelector('#password').classList.contains('reduce') && value.length >= 8) {
                        document.querySelector('#password').classList.remove('reduce');
                    }
                    if (event.code === 'Enter') {
                        this.sendForm();
                    }
                }
            },
            blur: (data, event) => {
                let value = event.target.value;
                if (fieldName === 'phone' && value.length > 0) {
                    if (this.validatePhone(value) || this.validatePhone(value)) {
                        document.querySelector('#phone').classList.remove('reduce');
                    } else {
                        document.querySelector('#phone').classList.add('reduce');
                    }
                }
                if (fieldName === 'password' && value.length > 0) {
                    if (value.length >= 8) {
                        document.querySelector('#password').classList.remove('reduce');
                    } else {
                        document.querySelector('#password').classList.add('reduce');
                    }
                }
            }
        };
    }

    authError(error) {
        this.errorPhone(this.i18next.t(error));
    }

    authSuccess() {
        this.Server.Request('config').then(res=>{
            let result = JSON.parse(res);
            if (result.auth) {

                this.Store.dispatch({ type: 'auth/set', payload:result.auth });

                this.Server.Subscribe('user_profile',result.authid).then(Subscribe=>{
                    Subscribe.on('update',(NewValue)=>this.Store.dispatch({ type: 'profile/setProfile', payload:{...NewValue} }));
                    this.Store.dispatch({ type: 'profile/setProfile', payload:{...Subscribe.get()} });
                    localStorage.setItem('user',JSON.stringify(Subscribe.get()));
                    let url = localStorage.getItem('url');
                    if(url){
                        localStorage.removeItem('url');
                        window.location.href = url;
                    }
                    else{
                        this.Router.navigate('/home');
                    }
                });
                this.Server.Subscribe('handler',result.authid).then(Subscribe=>{
                    Subscribe.on('update',(NewValue)=>{
                        this.Store.dispatch({type: 'handler/set', payload: {name: NewValue.type,params: {id: NewValue.extradata_id, data: NewValue.data}}});
                    });
                });

            } else {
                console.log(result.error);
            }
        }).catch(error=> console.log(error));
    }

    sendForm() {
        if(!this.phone() || !this.password()){
            this.errorMessage('Fields cannot be empty');
            return false;
        }
        this.errorMessage('');
        this.Server
            .Request('login_by_phone', {
                value: this.phone().replace(/\D/g, '') ,
                password: this.password()
            })
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    this.authSuccess();
                }else {
                    this.authError(result.error);
                }
            })
            .catch(e => this.authError(this.i18next.t(e)));


    }
}
