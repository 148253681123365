import { configureStore, createSlice } from '@reduxjs/toolkit';
export default configureStore({ reducer: { video : createSlice({
            name: 'video',
            initialState: { id: null, name:null, surname:null, bg:null, avatar:null, gender:null },
            reducers: {
                set: (state, action) => {
                    state.id = action.payload.id;
                    state.name = action.payload.name;
                    state.surname = action.payload.surname;
                    state.bg = action.payload.bg;
                    state.avatar = action.payload.avatar;
                    state.gender = action.payload.gender;
                },
                reset: (state, action) => {
                    state.id = null;
                    state.name = null;
                    state.surname = null;
                    state.bg = null;
                    state.avatar = null;
                    state.gender = null;
                }
            }
        }).reducer } });