import 'knockout-mapping';
import Handler from "engine/Handler";
import ko from 'knockout';
import intlTelInput from 'intl-tel-input';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import Store from "../../../redux/store";

export default class register extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});
        if (Store.getState().auth.value)
            this.Router.navigate('/');

        this.numberBlock = ko.observable('phone');
        this.showPassword = ko.observable(false);
        this.showPasswordRepeat = ko.observable(false);
        this.checkSMS = ko.observable(false);
        this.checkMail = ko.observable(false);
        this.checkNickname = ko.observable(false);
        this.errorAdd = ko.observable('');

        this.name = ko.observable('');
        this.email = ko.observable('');
        this.surname = ko.observable('');
        this.phone = ko.observable('');
        this.nickname = ko.observable('');

        this.truePhone = ko.observable('');
        this.errorSMS = ko.observable('');
        this.errorEmail = ko.observable('');
        this.errorNickname = ko.observable('');

        this.errorPhone = ko.observable('');
        this.sms = ko.observable('');
        this.mail_code = ko.observable('');
        this.password = ko.observable('');
        this.password_repeat = ko.observable('');

        this.validatePhone = ko.observable(false);
        this.passwordEmpty = ko.observable(false);
        this.passwordLength = ko.observable(true);
        this.passwordLat = ko.observable(true);
        this.passwordNumber = ko.observable(true);
        this.passwordSymbol = ko.observable(true);
        this.successfulEmailCode = ko.observable(false);

        this.showsecSMS = ko.observable(0);
        this.showsecMail = ko.observable(0);
        this.stopsend = ko.observable(0);
        this.smsRevText = ko.observable(true);

        this.licenseAgreement = ko.observable(false);
        this.correctDataEntry = ko.computed(() => this.licenseAgreement() && !this.passwordRequired() && !!this.checkPasswordRepeat() && this.validateNickname());

        this.shouldEnableNextButton = ko.computed(() => {
            return this.truePhone() && this.validatePhone();
        });

        this.shouldEnableNextButtonMail = ko.computed(() => {
            return this.validateEmail() && this.successfulEmailCode();
        });

        this.passwordRequired = ko.computed(function () {
            this.passwordEmpty(this.str_rot13() !== '');
            this.passwordLength(this.str_rot13().length < 8);
            this.passwordLat(!!(!/^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*[a-zA-Z]+[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]*$/.test(this.str_rot13())));
            this.passwordNumber(!!(!/\d/.test(this.str_rot13())));
            this.passwordSymbol(!!(!/[!@#$%^&*(),.?":{}|<>]/.test(this.str_rot13())));

            return !(this.passwordEmpty() === true && this.passwordLength() === false && this.passwordLat() === false && this.passwordNumber() === false && this.passwordSymbol() === false);
        }, this);

        //set text mask
        let text_input = document.querySelectorAll('input.text_input');
        text_input.forEach(function (input) {
            input.addEventListener('input', function (event) {
                let input = event.target.value;
                let regex = /^[a-zA-Zа-яА-Я\u0600-\u06FF\u0750-\u077F\u0590-\u05FF\u4E00-\u9FFF]+$/;

                if (!regex.test(input)) {
                    event.target.value = input.slice(0, -1); // Удалить последний символ, если он не буква
                }
            });
        });

        //set number mask
        const inputPhone = document.querySelector('#phone');

        const iti = intlTelInput(inputPhone, {
            preferredCountries: ['ru', 'us'],
            autoPlaceholder: 'polite',
            nationalMode: false,
            initialCountry: 'RU',
            placeholderNumberType: 'MOBILE',
            utilsScript:
                'js/utils.js',
        });

        inputPhone.addEventListener('input', () => {
            if (inputPhone.value.replace(/\D/g, '').length > 5) {
                this.validatePhone(!!iti.isValidNumber());
            } else {
                this.validatePhone(false);
            }
        });

        inputPhone.addEventListener('keyup', () => {
            if (inputPhone.value.replace(/\D/g, '').length > 5) {
                this.validatePhone(!!iti.isValidNumber());
            } else {
                this.validatePhone(false);
            }
        });

        inputPhone.addEventListener('keyup', () => {
            if (iti.isValidNumber()) {
                this.truePhone(window.intlTelInputGlobals.getInstance(inputPhone).getNumber());
                this.validatePhone(true);
            } else {
                this.truePhone('');
                this.validatePhone(false);
            }
        });
    }

    showPhoneStep() {
        this.numberBlock('phone');
        this.changeStateFunction('phone');
    }

    showSMSStep() {
        this.numberBlock('sms');
    }

    showEmailStep() {
        this.numberBlock('email');
        this.changeStateFunction('email');
    }

    showCheckEmailStep() {
        this.numberBlock('check_email');
    }

    showLastStep() {
        this.numberBlock('last');
        this.changeStateFunction('last');
    }

    changeState(param) {
        let activeItem = document.querySelector(".steps_block .active");
        if (activeItem) {
            let nextItem = param === 'prev' ? activeItem.previousElementSibling : document.querySelector("." + param);
            if (nextItem) {
                activeItem.classList.remove("active");
                nextItem.classList.add("active");
                switch (true) {
                    case nextItem.classList.contains("step_phone"):
                        this.showPhoneStep();
                        break;
                    case nextItem.classList.contains("step_sms"):
                        this.showSMSStep();
                        break;
                    case nextItem.classList.contains("step_email"):
                        this.showEmailStep();
                        break;
                    case nextItem.classList.contains("step_check_email"):
                        this.showCheckEmailStep();
                        break;
                    case nextItem.classList.contains("steps_last"):
                        this.showLastStep();
                        break;
                    default:
                        this.showPhoneStep();
                        break;
                }
            }
        }
    }

    changeStateFunction(number) {
        switch (number) {
            case "first":
                return !!this.name() && !!this.surname();
            case "phone":
                return !!this.truePhone() && this.validatePhone();
            case "email":
                return !!this.email() && this.validateEmail();
            case "default":
                return !!this.truePhone() && this.validatePhone() && !!this.email() && this.validateEmail() && !!this.checkSMS() && !!this.checkMail();
            case "last":
                return !this.passwordRequired() && !!this.password() && this.password() === this.password_repeat() && !!this.licenseAgreement() && !!this.validateNickname() && this.checkNickname();
        }
    }


    validateName() {
        const nameRegex = /^[a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?(?: [a-zA-Zа-яА-Я]+(?:-[a-zA-Zа-яА-Я]+)?)?$/;
        return this.name().length >= 3 && nameRegex.test(this.name());
    }

    validateEmail() {
        return /@[^.]+\.\w/.test(this.email());
    }

    validateNickname() {
        // Исключены    ! @ # $ % ^ & * ( )  + = [ ] { } \ | ; : ' " , . < > / ?
        return /^[a-zA-Z0-9_-]+$/.test(this.nickname());
    }

    str_rot13() {
        return this.password().replace(/[a-zA-Z]/g, (c) => {
            return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
        });
    }

    checkPasswordRepeat() {
        return this.password() && this.password() === this.password_repeat();
    }

    EventPassword() {
        return {
            keyup: function (d, event) {
                this.password(event.currentTarget.value);
            }
        };
    }

    SMSCountdown(stopsec) {
        let seconds = Math.floor(new Date().getTime() / 1000);
        let sendSMS = document.getElementById("sendSMS");
        let waitSendSMS = document.getElementById("waitSendSMS");
        let waitSecSMS = document.getElementById("waitSecSMS");

        this.showsecSMS(stopsec - seconds);
        if ((this.showsecSMS() <= 0 || this.stopsend() === 1) && (sendSMS !== null && waitSendSMS !== null)) {
            sendSMS.style.display = 'block';
            waitSendSMS.style.display = 'none';
            return;
        }
        if (waitSecSMS !== null) {
            waitSecSMS.innerHTML = this.showsecSMS();
        }
        const _self = this;
        setTimeout(function () {
            _self.SMSCountdown.call(this, stopsec);
        }.bind(_self), 1000);
    }

    sendSMSRequest() {
        this.Server
            .Request('add_phone_sms', {phone: this.truePhone().replace(/\D/g, '')})
            .then(res => {
                let result = JSON.parse(res);

                if (result.success) {
                    this.errorPhone('');
                    this.smsRevText(false);
                    this.changeState('step_sms');

                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");
                    this.smsRevText(false);

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecSMS();
                    this.SMSCountdown.call(this, seconds);
                }
            }).catch(error => {
            console.log(error);
            this.errorPhone(this.i18next.t(error));
            return false;
        });
    }

    sendCALLRequest() {
        this.Server
            .Request('add_phone_call', {phone: this.truePhone().replace(/\D/g, '')})
            .then(res => {
                let result = JSON.parse(res);
                if (result.success) {
                    let sendSMS = document.getElementById("sendSMS");
                    let waitSendSMS = document.getElementById("waitSendSMS");

                    if (sendSMS !== null && waitSendSMS !== null) {
                        sendSMS.style.display = 'none';
                        waitSendSMS.style.display = 'block';
                    }
                    this.stopsend(0);
                    this.showsecSMS(30);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecSMS();
                    this.SMSCountdown.call(this, seconds);
                    this.changeState('step_sms');
                    this.errorPhone('');
                }

            }).catch(error => {
            if (error === 'get_sms') {
                return this.sendSMSRequest();
            } else {
                this.errorPhone(this.i18next.t(error));
                return false;
            }
        });
    }

    validateSMS() {
        if (this.sms() === '') {
            this.errorSMS(this.i18next.t('code cannot be empty'));
            return false;
        }

        this.Server
            .Request('code_check', {phone: this.truePhone().replace(/\D/g, ''), code: this.sms()})
            .then(res => {
                res = JSON.parse(res);

                if (res.success) {
                    this.checkSMS(true);
                    this.errorSMS('');

                    this.changeState('step_email');
                }
            })
            .catch(e => {
                this.checkSMS(false);
                this.errorSMS(this.i18next.t(e));
            });
    }

    mailCountdown(stopsec) {
        let seconds = Math.floor(new Date().getTime() / 1000);
        let sendEmail = document.getElementById("sendEmail");
        let waitSendEmail = document.getElementById("waitSendEmail");
        let waitSecEmail = document.getElementById("waitSecEmail");
        this.showsecMail(stopsec - seconds);
        if ((this.showsecMail() <= 0 || this.stopsend() === 1) && (sendEmail !== null && waitSendEmail !== null)) {
            sendEmail.style.display = 'block';
            waitSendEmail.style.display = 'none';
            return;
        }
        if (waitSecEmail !== null) {
            waitSecEmail.innerHTML = this.showsecMail();
        }
        const _self = this;
        setTimeout(function () {
            _self.mailCountdown.call(this, stopsec);
        }.bind(_self), 1000);
    }

    sendEmailRequest() {
        this.Server
            .Request('add_email', {email: this.email()})
            .then(res => {
                let result = JSON.parse(res);

                if (result.success) {
                    let sendEmail = document.getElementById("sendEmail");
                    let waitSendEmail = document.getElementById("waitSendEmail");

                    if (sendEmail !== null && waitSendEmail !== null) {
                        sendEmail.style.display = 'none';
                        waitSendEmail.style.display = 'block';
                    }
                    this.errorEmail('');
                    this.stopsend(0);
                    this.showsecMail(60);
                    const seconds = Math.floor(new Date().getTime() / 1000) + this.showsecMail();
                    this.mailCountdown.call(this, seconds);
                    this.changeState('step_check_email');
                    this.successfulEmailCode(true);
                }
            }).catch(error => {
            this.errorEmail(error);
            return false;
        });
    }

    validateMail() {
        if (this.mail_code() === '') {
            this.errorEmail(this.i18next.t('code cannot be empty'));
            return false;
        }

        this.Server
            .Request('check_email', {email: this.email(), code: this.mail_code()})
            .then(res => {
                res = JSON.parse(res);
                if (res.success) {
                    this.checkMail(true);
                    this.errorEmail('');
                    this.changeState('steps_last');
                    // this.successfulEmailCode(true);
                }
            }).catch(error => {
            this.errorEmail(this.i18next.t(error));
            this.checkMail(false);
        });
    }

    validateFreeNickname() {
        this.Server
            .Request('check_free_nickname', {nickname: this.nickname()})
            .then(res => {
                res = JSON.parse(res);
                this.checkNickname(res.success);
                this.errorNickname(res.success ? '' : this.i18next.t(res.message));
            }).catch(error => {
            this.errorNickname(this.i18next.t(error));
            this.checkNickname(false);
        });
    }

    showToast(message, type) {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "top",
            position: "right",
            style: {
                background: type === "success" ? "info" : "red"
            },
            close: true,
        }).showToast();
    }

    sendForm() {
        this.Server
            .Request('add_auth', {pass: this.password()})
            .then(() => Promise.all([
                this.Server.Request('confirm_phone', {
                    phone: this.truePhone().replace(/\D/g, ''),
                    code: this.sms()
                }),
                this.Server.Request('confirm_email', {
                    email: this.email(),
                    code: this.mail_code()
                }),
                this.Server.Request('add_nickname', {nickname: this.nickname()})
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.success) {
                            this.Server.Request('user_registration', {
                                name: this.name(),
                                surname: this.surname(),
                                nickname: this.nickname()
                            })
                                .then(() => {
                                    this.Server.Subscribe('user_profile',res.authid).then(Subscribe=>{
                                        Subscribe.on('update',(NewValue)=>Store.dispatch({ type: 'profile/setProfile', payload:{...NewValue} }));
                                        this.Store.dispatch({ type: 'profile/setProfile', payload:{...Subscribe.get()} });
                                        localStorage.setItem('user',JSON.stringify(Subscribe.get()));
                                        let url = localStorage.getItem('url');
                                        if(url){
                                            localStorage.removeItem('url');
                                            window.location.href = url;
                                        }
                                    });
                                    this.Server.Subscribe('handler',res.authid).then(Subscribe=>{
                                        Subscribe.on('update',(NewValue)=>{
                                            this.Store.dispatch({type: 'handler/set', payload: {name: NewValue.type,params: {id: NewValue.extradata_id, data: NewValue.data}}});
                                        });
                                    });

                                    this.Server.Request('save_profile_settings', {
                                        show_me_in_search: true,
                                        name: this.name(),
                                        surname: this.surname(),
                                        nickname: this.nickname()
                                    }).catch(error => {
                                        this.showToast(this.i18next.t(error), 'error');
                                    });
                                    this.Store.dispatch({type: 'auth/set', payload: true});

                                    this.Router.navigate('/home');
                                })
                                .catch(error => {
                                this.showToast(this.i18next.t(error), 'error');
                            });
                        }
                    }).catch(error => {
                    this.errorNickname(this.i18next.t(error));
                    this.checkNickname(false);
                    this.showToast(this.i18next.t(error), 'error');
                })
            ]).then((result) => {
                result.forEach((element) => {
                    if (typeof element !== 'undefined') {
                        let res = JSON.parse(element);
                        if (res.error) {
                            this.errorAdd(this.i18next.t('save_user_error'));
                        }
                    }
                });
                if (!this.errorAdd()) {
                    this.showToast(this.i18next.t('Registration successful'), 'success');
                } else {
                    this.showToast(this.errorAdd(), 'error');
                }
            })).catch(e => {
            this.showToast('auth: ' + this.i18next.t(e), 'error');
        });
    }

    // sendForm() {
    //     this.Server
    //         .Request('add_auth', {pass: this.password()})
    //         .then(() => Promise.all([
    //             this.Server.Request('confirm_phone', {
    //                 phone: this.truePhone().replace(/\D/g, ''),
    //                 code: this.sms()
    //             }),
    //             this.Server.Request('confirm_email', {
    //                 email: this.email(),
    //                 code: this.mail_code()
    //             }),
    //             this.Server.Request('add_nickname', {nickname: this.nickname()})
    //                 .then((res) => {
    //                     res = JSON.parse(res);
    //                     if (res.success) {
    //                         this.Server.Request('user_registration', {
    //                             name: this.name(),
    //                             surname: this.surname(),
    //                             nickname: this.nickname()
    //                         }).catch(error => {
    //                             console.log(this.i18next.t(error));
    //                         });
    //
    //                         this.Server.Request('save_profile_settings', {
    //                             show_me_in_search: true,
    //                             name: this.name(),
    //                             surname: this.surname(),
    //                         }).catch(error => {
    //                             console.log(this.i18next.t(error));
    //                         });
    //                     }
    //                 }).catch(error => {
    //                 this.errorNickname(this.i18next.t(error));
    //                 this.checkNickname(false);
    //             })
    //         ]).then((result) => {
    //             result.forEach((element) => {
    //                 if (typeof element !== 'undefined') {
    //                     let res = JSON.parse(element);
    //                     if (res.error) {
    //                         this.errorAdd(this.i18next.t('save_user_error'));
    //                     }
    //                 }
    //             });
    //             if (!this.errorAdd()) {
    //                 this.Store.dispatch({type: 'auth/set', payload: true});
    //                 this.Store.dispatch({ type: 'profile/setProfile', payload:{
    //                         name: this.name(),
    //                         surname: this.surname(),
    //                         nickname: this.nickname()
    //                     }});
    //                 this.Router.navigate('/home');
    //             } else {
    //                 alert(this.errorAdd());
    //             }
    //         })).catch(e => {
    //         alert('auth: ' + this.i18next.t(e));
    //     });
    // }
}

