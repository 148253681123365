import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Caller from '/redux/caller';
import Audio from '/redux/audio';
import Video from '/redux/video';
import messageToStream from '/redux/messageToStream';
import Store from "../../../redux/store";

export default class modals extends Handler {
    constructor({Store, Router, Server, i18next}) {
        super({Store, Router, Server, i18next});
        this.Subscribes = [];
        this.audioRemoteMuted = ko.observable(false);
        this.VIDEO_CONSTRAINS =
            {
                qvga : { width: { exact: 320 }, height: { exact: 240 } },
                vga  : { width: { exact: 640 }, height: { exact: 480 } },
                hd   : { width: { exact: 1280 }, height: { exact: 960 } }
            };
        this.Quality = ko.observable('vga');
        this.facingMode = ko.observable('user');


        this.User = ko.mapping.fromJS(Store.getState().profile.profile);


        this.UserAudio = ko.mapping.fromJS(Audio.getState().audio);
        this.UserVideo = ko.mapping.fromJS(Video.getState().video);
        this.AudioTarck = ko.observableArray([]);
        this.VideoTarck = ko.observableArray([]);
        this.modalDataObject = ko.observable({ id : 0 });

        this.caller = ko.mapping.fromJS(Caller.getState().caller);

        this.Subscribes.push(Caller.subscribe(() => this.updateField(this.caller, Caller.getState().caller, 'audio', 'video', 'userid')));

        this.Subscribes.push(messageToStream.subscribe(() => {
            let state = messageToStream.getState().messageToStream;
            if(state.action){
                //messageToStream.dispatch({ type: 'messageToStream/remove' });
                this.startToAudioStream(state.message).catch(e=>console.log(e));
            }
        }));

        this.Subscribes.push(Audio.subscribe(() => {
            if(Audio.getState().audio.id){
                new bootstrap.Modal(document.getElementById('callingScreen')).show();
                if(this.caller.audio()){
                    this.Server.Ringtone.play().catch(e=>console.log(e));

                }else{
                    if(Audio.getState().audio.gender === 'female'){
                        this.Server.gudokm.play().catch(e=>console.log(e));
                    }else{
                        this.Server.gudokg.play().catch(e=>console.log(e));
                    }
                    this.CreateAudioCall(Audio.getState().audio);
                }
                Server.Subscribe('user_profile',Audio.getState().audio.id).then(s=>s.on('update',(payload)=>Audio.dispatch({ type: 'audio/set', payload })));
                this.updateField( this.UserAudio, Audio.getState().audio, 'id', 'name', 'surname', 'bg', 'avatar', 'gender' );
            }else{
                Caller.dispatch({ type: 'caller/reset' });
                this.Server.stopTracksCansume();
                this.Server.stopTracks();
                this.Server.Ringtone.pause();
                this.Server.gudokg.pause();
                this.Server.gudokm.pause();
                this.AudioTarck([]);
                bootstrap.Modal.getInstance(document.getElementById('callingScreen')).hide()
                document.getElementsByClassName("modal-backdrop")[0].remove();
            }
        }));

        this.Subscribes.push(Video.subscribe(() => {
            if(Video.getState().video.id){
                new bootstrap.Modal(document.getElementById('videoCallingScreen')).show();
                if(this.caller.video()){
                    this.Server.Ringtone.play().catch(e=>console.log(e));

                }else{
                    if(Video.getState().video.gender === 'female'){
                        this.Server.gudokm.play().catch(e=>console.log(e));
                    }else{
                        this.Server.gudokg.play().catch(e=>console.log(e));
                    }
                    this.CreateVideoCall(Video.getState().video);
                }
                Server.Subscribe('user_profile',Video.getState().video.id).then(s=>s.on('update',(payload)=>Video.dispatch({ type: 'audio/set', payload })));
                this.updateField( this.UserVideo, Video.getState().video, 'id', 'name', 'surname', 'bg', 'avatar', 'gender' );
            }else{
                Caller.dispatch({ type: 'caller/reset' });
                this.Server.stopTracksCansume();
                this.Server.stopTracks();
                this.Server.Ringtone.pause();
                this.Server.gudokg.pause();
                this.Server.gudokm.pause();
                this.AudioTarck([]);
                this.VideoTarck([]);
                bootstrap.Modal.getInstance(document.getElementById('videoCallingScreen')).hide()
                document.getElementsByClassName("modal-backdrop")[0].remove();
            }
        }));


        this.Subscribes.push(Store.subscribe(() => {
            let state = Store.getState();
            const profile = state.profile.profile;
            this.User.name(profile.name);
            this.User.surname(profile.surname);
            this.User.id(profile.id);
            this.User.avatar(profile.avatar);
            if (state.modalHandler.name === 'deleteModalMessage') {
                this.modalDataObject(state.modalHandler.modalData);
            }
            if (state.modalHandler.name === 'deleteMessageForEveryone') {
                this.modalDataObject(state.modalHandler.modalData);
            }
        }));

        this.unsubscribe = () => this.Subscribes.forEach(f=>f());


        this.searchname = ko.observable('');
        this.seachList = ko.observableArray([]);
        this.contactAddInList = ko.observableArray([]);
        this.textTheAddButton = ko.observable(false);
        document.querySelector('#addContact').addEventListener('hide.bs.modal', () => {
            this.contactAddInList.removeAll();
            this.searchname('');
        });

        this.searcChat = ko.observable('');
        document.querySelector('#newChat').addEventListener('hide.bs.modal', () => {
            this.contactAddInList.removeAll();
            this.searcChat('');
        });

    }

    async startToAudioStream(message){
        let addStream = message.message_text;
        if(typeof message.message_text === 'string'){
             addStream = JSON.parse(message.message_text);
        }
        return this.getAudioStream()
            .then(stream=>{
                return this.sendAudioStream(stream).then((producer)=>{
                    const filtered = addStream.filter(item=>item.userid!==this.Store.getState().profile.profile.id);
                    filtered.push({
                        "id": ko.observable(producer.id),
                        "kind":ko.observable(producer.kind),
                        "userid":ko.observable(this.Store.getState().profile.profile.id)
                    });
                    return this.Server.Request('update_message', {
                        value: JSON.stringify(ko.mapping.toJS(filtered)),
                        id: message.id,
                        id2id : message.id2id,
                        user_id: message.sender_id
                    });

                });
            })
            .catch(e=>console.log(e));
    }

    async applyAudioStream(){
        this.goToAudioStream(ko.mapping.fromJS(messageToStream.getState().messageToStream.message)).catch(e=>console.log(e))
        messageToStream.dispatch({ type: 'messageToStream/start' });
        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        let caller = Caller.getState().caller;
        Caller.dispatch({ type: 'caller/reset' });
        if(Number(caller.userid)!==Number(this.Store.getState().profile.profile.id)){
            this.Server.getTrack(caller.audio)
                .then(track=>{
                    if(track.kind === 'audio'){
                        this.AudioTarck.push({ stream:new MediaStream([track]), muted :  this.audioRemoteMuted, userid : caller.userid });
                    }
                }).catch(e=>console.log(e));
        }
    }

    async applyVideoStream(){
        this.goToVideoStream(ko.mapping.fromJS(messageToStream.getState().messageToStream.message)).catch(e=>console.log(e))
        messageToStream.dispatch({ type: 'messageToStream/start' });
        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        let caller = Caller.getState().caller;
        Caller.dispatch({ type: 'caller/reset' });
        if(Number(caller.userid)!==Number(this.Store.getState().profile.profile.id)){

            this.Server.getTrack(caller.video)
                .then(track=>{
                    if(track.kind === 'video'){
                        this.VideoTarck.push({ stream:new MediaStream([track]), muted :  this.audioRemoteMuted, userid : caller.userid });
                    }
                }).catch(e=>console.log(e));

            this.Server.getTrack(caller.audio)
                .then(track=>{
                    if(track.kind === 'audio'){
                        this.AudioTarck.push({ stream:new MediaStream([track]), muted :  this.audioRemoteMuted, userid : caller.userid });
                    }
                }).catch(e=>console.log(e));

        }
    }


    async goToAudioStream(message){
        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        if(typeof message.message_text() ==='string'){
            message.message_text(ko.mapping.fromJS(JSON.parse(message.message_text()))());
        }
        let addStream = message.message_text();
        return this.getAudioStream()
            .then(stream=>{
                return this.sendAudioStream(stream).then((producer)=>{
                    const filtered = addStream.filter(item=>item.userid()!==this.Store.getState().profile.profile.id);
                    filtered.push({
                        "id": ko.observable(producer.id),
                        "kind":ko.observable(producer.kind),
                        "userid":ko.observable(this.Store.getState().profile.profile.id)
                    });
                    return this.Server.Request('update_message', {
                        value: JSON.stringify(ko.mapping.toJS(filtered)),
                        id: message.id(),
                        id2id : message.id2id(),
                        user_id: message.sender_id()
                    });
                });
            })
            .catch(e=>console.log(e));
    }

    async goToVideoStream(message){
        this.Server.Ringtone.pause();
        this.Server.gudokg.pause();
        this.Server.gudokm.pause();
        if(typeof message.message_text() ==='string'){
            message.message_text(ko.mapping.fromJS(JSON.parse(message.message_text()))());
        }
        let addStream = message.message_text();
        return this.getVideoStream()
            .then(stream=>{
                return this.sendVideoStream(stream).then(([producer1,producer2])=>{

                    const filtered = addStream.filter(item=>item.userid()!==this.Store.getState().profile.profile.id);

                    filtered.push({
                        "id": ko.observable(producer1.id),
                        "kind":ko.observable(producer1.kind),
                        "userid":ko.observable(this.Store.getState().profile.profile.id)
                    });
                    filtered.push({
                        "id": ko.observable(producer2.id),
                        "kind":ko.observable(producer2.kind),
                        "userid":ko.observable(this.Store.getState().profile.profile.id)
                    });

                    return this.Server.Request('update_message', {
                        value: JSON.stringify(ko.mapping.toJS(filtered)),
                        id: message.id(),
                        id2id : message.id2id(),
                        user_id: message.sender_id()
                    });
                });
            })
            .catch(e=>console.log(e));
    }

    async endCall(){
        this.Server.Request('update_message', {
            value: JSON.stringify([]),
            id: messageToStream.getState().messageToStream.message.id,
            id2id : messageToStream.getState().messageToStream.message.id2id,
            user_id: messageToStream.getState().messageToStream.message.sender_id
        }).catch(e=>console.log(e));
        Audio.dispatch({ type: 'audio/reset' });
    }

    async endCallVideo(){
        this.Server.Request('update_message', {
            value: JSON.stringify([]),
            id: messageToStream.getState().messageToStream.message.id,
            id2id : messageToStream.getState().messageToStream.message.id2id,
            user_id: messageToStream.getState().messageToStream.message.sender_id
        }).catch(e=>console.log(e));
        Video.dispatch({ type: 'video/reset' });
    }

    async sendAudioStream(stream){
        return  this.Server.sendTrack(stream.getTracks()[0]);
    }

    async sendVideoStream(stream){
        let [track1,track2] = stream.getTracks();
        if(track1 && track2){
            if(track1.kind === 'video'){
                this.VideoTarck.push({ stream : new MediaStream([ track1 ]), muted :  this.audioRemoteMuted , userid : this.Store.getState().profile.profile.id });
            }
            if(track2.kind === 'video'){
                this.VideoTarck.push({ stream : new MediaStream([ track2 ]), muted :  this.audioRemoteMuted, userid : this.Store.getState().profile.profile.id });
            }
            return Promise.all([
                this.Server.sendTrack(track1).then(({ id, kind })=>({ id, kind, label:'cam' })),
                this.Server.sendTrack(track2).then(({ id, kind })=>({ id, kind, label:'cam' })),
            ])
        }
    }

    async deviceId() {
        let deviceId = 'default';
        if (!!navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach(device => {
                if (device.kind === 'audioinput' && device.label === 'Speakerphone') {
                    deviceId = device.deviceId;
                }
            });
        }
        return deviceId;
    }

    async getVideoStream(){
        return this.deviceId().then(deviceId=>navigator.mediaDevices.getUserMedia({
            audio: { useDtx: true, autoGainControl: true, noiseSuppression: true, echoCancellation: true, deviceId: { ideal: deviceId } },
            video: { ...this.VIDEO_CONSTRAINS[this.Quality()], facingMode: this.facingMode(), resizeMode:'crop-and-scale' }
        }));
    }

    async getAudioStream(){
        return this.deviceId().then(deviceId=>navigator.mediaDevices.getUserMedia({
            audio: { useDtx: true, autoGainControl: true, noiseSuppression: true, echoCancellation: true, deviceId: { ideal: deviceId } },
            video: false
        }));
    }


    CreateAudioCall({ name, surname, bg, avatar, id, gender }) {
        this.getAudioStream()
            .then(stream=>{
                this.sendAudioStream(stream).then((producer)=>{
                    this.Server.Request( 'send_message', {
                        value : JSON.stringify([{ "id": producer.id, "kind":producer.kind , "userid":this.Store.getState().profile.profile.id }]),
                        user_id : id,
                        type : 'audio'
                    })
                        .then( res => {
                            let { message } = JSON.parse(res);
                            messageToStream.dispatch({ type: 'messageToStream/set', payload:  {message:{ ...message }} });
                            message.message_text = JSON.parse(message.message_text);
                            let newMessage = ko.mapping.fromJS(message);
                            newMessage.message_text([]);
                            this.Server
                                .Subscribe('messages', newMessage.id() )
                                .then( message => message.on( 'update', ( newValue ) => {
                                    if(JSON.parse( newValue.message_text ).length === 0){
                                        Audio.dispatch({ type: 'audio/reset'});
                                    }
                                    if(JSON.parse( newValue.message_text ).length === 2){
                                        JSON.parse( newValue.message_text ).forEach( stream =>{
                                            newMessage.message_text.push( ko.mapping.fromJS( stream ));

                                                if(Number(stream.userid)!==Number(this.Store.getState().profile.profile.id)){
                                                    if(this.UserAudio.id() === stream.userid){

                                                        this.Server.getTrack(stream.id)
                                                            .then(track=>{
                                                                if(track.kind === 'audio'){
                                                                    this.AudioTarck.push({ stream : new MediaStream([ track ]), muted :  this.audioRemoteMuted });
                                                                }
                                                                this.Server.Ringtone.pause();
                                                                this.Server.gudokg.pause();
                                                                this.Server.gudokm.pause();
                                                            }).catch(e=>console.log(e));
                                                    }
                                                }
                                        })
                                    }
                                }));
                        }).catch( e => console.log( e ) );
                });
            })
            .catch(e=>console.log(e));
    }

    CreateVideoCall({ name, surname, bg, avatar, id, gender }) {
        this.getVideoStream()
            .then(stream=>{
                this.sendVideoStream(stream).then(([producer1,producer2])=>{
                    this.Server.Request( 'send_message', {
                        value : JSON.stringify([
                            { "id": producer1.id, "kind":producer1.kind , "userid":this.Store.getState().profile.profile.id },
                            { "id": producer2.id, "kind":producer2.kind , "userid":this.Store.getState().profile.profile.id }
                        ]),
                        user_id : id,
                        type : 'video'
                    })
                        .then( res => {
                            let { message } = JSON.parse(res);
                            messageToStream.dispatch({ type: 'messageToStream/set', payload:  {message:{ ...message }} });
                            message.message_text = JSON.parse(message.message_text);
                            let newMessage = ko.mapping.fromJS(message);
                            newMessage.message_text([]);
                            this.Server
                                .Subscribe('messages', newMessage.id() )
                                .then( message => message.on( 'update', ( newValue ) => {
                                    if(JSON.parse( newValue.message_text ).length === 0){
                                        Video.dispatch({ type: 'video/reset'});
                                    }
                                    if(JSON.parse( newValue.message_text ).length === 4){
                                        JSON.parse( newValue.message_text ).forEach( stream =>{
                                            newMessage.message_text.push( ko.mapping.fromJS( stream ));

                                                if(Number(stream.userid)!==Number(this.Store.getState().profile.profile.id)){
                                                    if(this.UserVideo.id() === stream.userid){

                                                        this.Server.getTrack(stream.id)
                                                            .then(track=>{
                                                                if(track.kind === 'audio'){
                                                                    this.AudioTarck.push({ stream : new MediaStream([ track ]), muted :  this.audioRemoteMuted, userid : stream.userid });
                                                                }
                                                                if(track.kind === 'video'){
                                                                    this.VideoTarck.push({ stream : new MediaStream([ track ]), muted :  this.audioRemoteMuted, userid : stream.userid });
                                                                }
                                                                this.Server.Ringtone.pause();
                                                                this.Server.gudokg.pause();
                                                                this.Server.gudokm.pause();
                                                            })
                                                    }
                                                }
                                        })
                                    }
                                }));
                        })
                });
            })

    }

    toggleSwap() {
        document.getElementById('videoLarge').classList.toggle('swapped');
        document.getElementById('videoSmall').classList.toggle('swapped');
    }

    deleteMessage() {
        const {id, recipient_id, sender_id} = this.modalDataObject();
        return this.Server.Request('delete_message', {id, recipient_id, sender_id})
            .then(() => this.Store.dispatch({type: 'handler/set', payload: {name: 'delete_message', params: {id, data: {id, recipient_id, sender_id}}}}))
            .catch((e) => console.log(e));
    }
    deleteMessageForEveryone() {
        const {id, id2id, recipient_id, sender_id} = this.modalDataObject();
        return this.Server.Request('delete_message_both', {id, id2id, recipient_id, sender_id})
            .then(() => this.Store.dispatch({type: 'handler/set', payload: {name: 'delete_message_both', params: {id, data: {id, recipient_id, sender_id}}}}))
            .catch((e) => console.log(e));
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    searchContactsByNameEvent(tab) {
        return {
            input: (data, event) => {
                if (tab === 'contacts') {
                    if (event.target.value.length > 0) {
                        this.debounce(400, () => {
                            this.Server.Request('get_search_profiles', {search_text: event.target.value}).then(response => {
                                response = JSON.parse(response);
                                this.seachList([]);
                                if (response.success) {
                                    response.profiles.forEach(user => {

                                        let resultProfile = ko.mapping.fromJS({
                                            name: '',
                                            surname: '',
                                            avatar: '',
                                            nickname: '',
                                            id: 0,
                                            authid: 0,
                                            is_friend: Object.values(user)[0]
                                        });

                                        this.seachList.push(resultProfile);

                                        this.Server.Subscribe('user_profile', Object.keys(user)[0]).then(profile => {
                                            this.updateField(resultProfile, profile.get(), 'name', 'surname','nickname' , 'avatar', 'id', 'authid');
                                            profile.on('update', (newValue) => {
                                                this.updateField(resultProfile, newValue, 'name', 'surname','nickname' , 'avatar');
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    } else {
                        this.seachList([]);
                    }
                }
            }
        };
    }

    searchContacts() {
        return {
            input: (data, event) => {
                if (event.target.value.length > 0) {
                    this.debounce(400, () => {
                        this.Server.Request('get_search_contacts', {search_text: event.target.value}).then(response => {
                            response = JSON.parse(response);
                            this.seachList([]);
                            if (response.success) {
                                response.profiles.forEach(user => {
                                    if (this.Store.getState().openDialogueHandler.id !== user) {
                                        let resultProfile = ko.mapping.fromJS({
                                            name: '',
                                            surname: '',
                                            avatar: '',
                                            nickname: '',
                                            id: 0,
                                            authid: 0,
                                            is_friend: true
                                        });
                                        this.seachList.push(resultProfile);
                                        this.Server.Subscribe('user_profile', user).then(profile => {
                                            this.updateField(resultProfile, profile.get(), 'name', 'surname','nickname' , 'avatar', 'id', 'authid');
                                            profile.on('update', (newValue) => {
                                                this.updateField(resultProfile, newValue, 'name', 'surname','nickname' , 'avatar');
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    });
                } else {
                    this.seachList([]);
                }
            }
        };
    }

    updateField(profile, newVal, ...fieldsName) {
        fieldsName.forEach(name => {
            profile[name](newVal[name]);
        });
    }

    addUserSubscribe(id) {
        this.Server.Subscribe('user_profile',id).then(Subscribe=>{
            let user = Subscribe.get();
            this.Server.Subscribe('profile_settings',id).then(res=>{
                this.Store.dispatch({type: 'contacts/add', payload: {...user, ...res.get()}});
            });
        });
    }

    sendContact(nickname, id) {
        this.Server
            .Request('send_message', {
                // value: '<a href="'+window.location.protocol  + '//' + window.location.host + '/contacts/' + nickname+'">'+ nickname +'</a>',
                value: JSON.stringify({"id":id,"link":`${window.location.protocol}//${window.location.host}/contacts/${nickname}`}),
                user_id: this.Store.getState().openDialogueHandler.id,
                type: 'contact'
            }).catch(e => console.log(e));
    }

    addNewContact(contact_user_id) {
        this.Server.Request('add_contact', {contact_user_id})
            .then(() => {
                this.textTheAddButton(true);
                this.contactAddInList.push(contact_user_id);
                this.addUserSubscribe(contact_user_id);
            })
            .catch((e) => console.error(e));
    }

    isContact(speaker) {
        return ko.utils.arrayFilter(this.contactAddInList(), (item) => {
            if (item === speaker) return item;
        }).length >= 1;
    }

    startChatDialog(id) {
        this.Router.navigate('/dialog/' + id);
    }

}
