import contacts from 'PagesModels/contacts';
import forgot from 'PagesModels/forgot';
import home from 'PagesModels/home';
import login from 'PagesModels/login';
import profile from 'PagesModels/profile';
import register from 'PagesModels/register';
import not_found from 'PagesModels/not_found';
import 'binding/imageByID';
import 'binding/audioStream';
import 'binding/image';
import 'binding/include';
import * as bootstrap from 'bootstrap'; window.bootstrap = bootstrap;
export default { contacts, forgot, home, login, profile, register, not_found };