import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Audio from '/redux/audio';
import Video from '/redux/video';

export default class home extends Handler {
    constructor({Store, Router, Server, i18next, Request}) {
        super({Store, Router, Server, i18next});
        this.dialogsList = ko.observableArray([]);
        this.searchDialogsList = ko.observableArray([]);
        this.DialogAuthid = ko.observable(0);
        this.searchChat = ko.observable('');
        this.searchTab = ko.observable(false);
        this.currentNickname = ko.observable(Request.params.nickname);
        this.blacklistChatId = ko.observable(null);
        this.blacklistUserId = ko.observable(null);
        this.type = ko.observable('active');
        this.all_dialogs_length = ko.observable(0);
        this.offset = ko.observable(0);
        this.isDialogSelected = ko.observable(false);
        this.contactIdsList = ko.observableArray([]);

        this.unsubscribe = Store.subscribe(() => {
            if (Object.keys(Store.getState().dialogHandler.dialog).length !== 0 && !this.isExist(Store.getState().dialogHandler.dialog)) {
                this.addObservableDialog(Store.getState().dialogHandler.dialog);
            }
            if (Store.getState().handler.name === 'dialog' && !this.isExist(Store.getState().handler.params.id) ) {
                this.Server.Subscribe('dialogs', Store.getState().handler.params.id).then(Subscribe => {
                    this.addObservableDialog(Subscribe.get());
                });
            } else if (Store.getState().handler.name === 'dialog' && this.isExist(Store.getState().handler.params.id)) {
                ko.utils.arrayMap(this.dialogsList(), (contact) => {
                    if (contact.id() === Store.getState().handler.params.id) {
                        contact.last_message(Store.getState().handler.params.data.last_message);
                        contact.updatedAt(Store.getState().handler.params.data.updatedAt);
                        contact.count(Store.getState().handler.params.data.count);
                    }
                    return contact;
                });
            }
        });

        this.Store.dispatch({type: 'open_dialogue/set', payload: 0});

        if (!!Request.params.id) this.setDialogId(Request.params.id);

        this.limit = 20;

        this.getListData('get_my_dialogs_by_status', this.type());

        document.querySelector('.tyn-aside-body').addEventListener('scroll', (e) => {
            if (Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight && this.all_dialogs_length() > this.offset()) {
                this.offset(this.dialogsList().length);
                this.getListData('get_my_dialogs_by_status', this.type());
            }
        });

        this.type.subscribe((type) => {
            this.offset(0);
            this.getListData('get_my_dialogs_by_status', type);
            this.dialogsList([]);
        });

        this.getMyContacts();

    }

    isExist(id) {
        return ko.utils.arrayFilter(this.dialogsList(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    getListData(route, status) {
        this.Server.Request(route, {limit: this.limit, offset: this.offset(), status: status})
            .then(res => {
                const response = JSON.parse(res);
                this.all_dialogs_length(response.count);

                response.dialogs.forEach((res) => {
                    if (response.success) {
                        this.addObservableDialog(res, 'dialogsList');
                    }
                });
            }).catch((e) => console.log(e));
    }


    addNewContact(contact_user_id) {
        this.Server.Request('add_contact', {contact_user_id})
            .then(() => {
                this.contactIdsList.push(contact_user_id);
            })
            .catch((e) => console.error(e));
    }

    isContact(speaker) {
        return ko.utils.arrayFilter(this.contactIdsList(), (item) => {
            if (item === speaker) return item;
        }).length >= 1;
    }

    getMyContacts() {
        this.Server.Request('all_my_contacts')
            .then(res => {
                const response = JSON.parse(res);
                this.contactIdsList(response.users);
            }).catch((e) => console.log(e));
    }

    setDialogId(id) {
        this.Router.setLocation('/dialog/' + id);

        if (Number(id) > 0 && Number(this.Store.getState().openDialogueHandler.id) !== Number(id)) {
            this.DialogAuthid(Number(id));
            this.Store.dispatch({type: 'open_dialogue/set', payload: Number(id)});
            //setTimeout(()=>{
           // },0);
        }
        document.getElementById('tynMain').classList.add('main-shown');

        this.isDialogSelected(true);
    }

    Audio( payload ) {
        this.Server.Subscribe('user_profile',payload.userid).then(Subscribe=>{
            Audio.dispatch({ type: 'audio/set', payload:Subscribe.get() });
                Subscribe.on('update',(NewValue)=>{
                    Audio.dispatch({ type: 'audio/set', payload:NewValue });
                });
        });
    }

    Video( payload ) {
        this.Server.Subscribe('user_profile',payload.userid).then(Subscribe=>{
            Video.dispatch({ type: 'video/set', payload:Subscribe.get() });
                Subscribe.on('update',(NewValue)=>{
                    Video.dispatch({ type: 'video/set', payload:NewValue });
                });
        });
    }

    getDateFormat(item) {
        const date = new Date(item);
        let dayOfMonth = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let diffMs = new Date() - date;
        let diffSec = Math.round(diffMs / 1000);
        let diffMin = Math.round(diffSec / 60);
        let diffHour = Math.round(diffMin / 60);
        let diffDays = Math.round(diffHour / 24);

        // форматирование
        month = month < 10 ? '0' + month : month;
        dayOfMonth = dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth;
        hour = hour < 10 ? '0' + hour : hour;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        if (diffSec < 1) {
            return this.i18next.t('just now');
        } else if (diffMin < 1) {
            return diffSec + ' ' + this.i18next.t('sec. ago');
        } else if (diffHour < 1) {
            return diffMin + ' ' + this.i18next.t('min. ago');
        } else if (diffDays < 1) {
            return diffHour + ' ' + this.i18next.t('h. ago');
        } else if (diffDays < 2) {
            return this.i18next.t('yesterday') + ' ' + hour + ':' + minutes;
        } else {
            return dayOfMonth + '.' + month + '.' + year + ' ' + hour + ':' + minutes;
        }
    }

    getMessageByType(str) {
        let result = '';
        switch (str) {
            case '_audio': result = this.i18next.t('Audio Call');
                break;
            case '_video': result = this.i18next.t('Video Call');
                break;
            case '_video_file': result = this.i18next.t('Video');
                break;
            case '_files': result = this.i18next.t('Files');
                break;
            case '_images': result = this.i18next.t('Image');
                break;
            case '_contact': result = this.i18next.t('Contacts');
                break;
            default: result = str;
                break;
        }
        return result;
    }

    addObservableDialog(res) {
        const { last_message, userid, id, status, count, updatedAt, name, surname, nickname, authid, avatar, bg } = res;

        if (this.isExist(res.id)) return false;

        let dialogItem = ko.mapping.fromJS({
            last_message,
            userid,
            id,
            updatedAt,
            name,
            count,
            surname,
            nickname,
            authid,
            bg,
            avatar,
            status
        });

        this.dialogsList.push(dialogItem);

        this.Server.Subscribe('dialogs', res.id).then(Subscribe => {
            this.updateField(dialogItem, Subscribe.get(), 'last_message', 'updatedAt');
            Subscribe.on('update', (newVal) => {
                this.Store.dispatch({ type: 'message/setMessage', payload: newVal });
                this.updateField(dialogItem, newVal, 'last_message', 'updatedAt');
            });
        });

        this.Server.Subscribe('user_profile', res.userid).then(Subscribe => {
            this.updateField(dialogItem, Subscribe.get(), 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg');
            Subscribe.on('update', (newVal) => {
                this.updateField(dialogItem, newVal, 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg');
            });
        });
    }

     readAllMessages(dialogId) {
        this.Server.Request('read_messages_to_dialogue', { dialogId }).then(res => {
            res = JSON.parse(res);
            if (res.success) {
                console.log(res);
            }
        }).catch(e=>console.log(e));
     }

    updateField(dialog, newVal, ...fieldsName) {
        fieldsName.forEach(field => {
            if (newVal.hasOwnProperty(field)) {
                dialog[field](newVal[field]);
            }
        });
    }

    addChatToArchive(userid, id) {
        this.Server.Request('archive_dialog', {userid, status: 'archive'})
            .then((res) => {
                res = JSON.parse(res);
                if (res.success) {
                    this.changeFlagItem('archive', id, res.dialog.status);
                }
            })
            .catch((error) => {
                console.error('archive_dialog', error);
            });
    }

    deleteChatFromArchive(userid, id) {
        this.Server.Request('delete_from_archive', {userid, status: 'active'})
            .then((res) => {
                res = JSON.parse(res);

                this.dialogsList.remove((item) => {
                    return item.id() === id;
                });

                if (res.success) {
                    this.changeFlagItem('archive', id, res.dialog.status);
                }
            })
            .catch((e) => console.log(e));
    }

    showBlacklistModal(userid, id) {
        this.blacklistUserId(userid);
        this.blacklistChatId(id);
    }

    confirmBlacklistChat() {
        this.addChatToBlacklist(this.blacklistUserId(), this.blacklistChatId());
    }

    addChatToBlacklist(userid, id) {
        this.Server.Request('blacklist_dialog', {userid, status: 'chat-blacklist'})
            .then((res) => {
                res = JSON.parse(res);
                this.changeFlagItem('chat-blacklist', id, res.dialog.status);
            })
            .catch((error) => {
                console.error('blacklist_dialog', error);
            });
    }

    deleteChatFromBlacklist(userid, id) {
        this.Server.Request('remove_chat_from_blacklist', {userid, status: 'active'})
            .then((res) => {
                res = JSON.parse(res);
                this.dialogsList.remove((item) => {
                    return item.id() === id;
                });
                this.changeFlagItem('chat-blacklist', id, res.dialog.status);
            })
            .catch((e) => console.log(e));
    }

    changeFlagItem(flag_name, id) {
        ko.utils.arrayMap(this.dialogsList(), (contact) => {
            if (id === contact.id()) contact.status(flag_name);
            return contact;
        });
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    searchDialogs() {
        return {
            input: (data, event) => {
                const searchText = event.target.value.toLowerCase();
                if (searchText.length > 0) {
                    this.searchTab(true);
                    this.debounce(400, () => {
                        this.Server.Request('get_search_dialogs', {
                            search_text: searchText,
                            limit: this.limit,
                            offset: this.offset()
                        })
                            .then(res => {
                                res = JSON.parse(res);
                                this.searchDialogsList([]);
                                // console.log(res)
                                res.dialogs.forEach(dialog => {
                                    const dataDialog = ko.mapping.fromJS({
                                        authid: dialog.authid,
                                        name: '',
                                        surname: '',
                                        nickname: '',
                                        lastMessage: dialog.last_message,
                                        avatar: '',
                                        id: dialog.id,
                                        updatedAt: dialog.updatedAt,
                                        status: dialog.status,
                                        userid: dialog.userid,
                                        bg: ''
                                    });

                                    this.Server.Subscribe('dialogs', dialog.id).then(res => {
                                        res.on('update', (newValue) => this.updateField(dataDialog, newValue, 'last_message', 'updatedAt', 'status'));
                                    });

                                    this.Server.Subscribe('user_profile', dialog.userid).then(profile => {
                                        const {name, surname, nickname} = profile.get();
                                        this.updateField(dataDialog, profile.get(), 'name', 'surname', 'nickname', 'avatar', 'bg');
                                        profile.on('update', (newValue) => {
                                            this.updateField(dataDialog, newValue, 'name', 'surname', 'nickname', 'avatar', 'bg');
                                        });
                                        const fullName = `${name.toLowerCase()} ${surname.toLowerCase()}`;

                                        if (fullName.includes(searchText) || nickname.toLowerCase().includes(searchText.trim())) {
                                            this.searchDialogsList.push(dataDialog);
                                        }
                                    });
                                });
                            })
                            .catch(e => console.log(e));
                    });
                } else {
                    this.searchTab(false);
                    this.searchDialogsList([]);
                }
            }
        };
    }
}
