import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import Caller from '/redux/caller';
import Audio from '/redux/audio';
import Video from '/redux/video';

export default class contacts extends Handler {
    constructor({Store, Router, Server, i18next, Request}) {
        super({ Store, Router, Server, i18next });

        this.contactList = ko.observableArray([]);
        this.seachList = ko.observableArray([]);
        this.blackList = ko.observableArray([]);
        this.listOfFavoriteContacts = ko.observableArray([]);
        this.searchTab = ko.observable(false);
        this.currentId = ko.observable(Request.params.id);
        this.deletedObj = ko.observable(0);
        this.deleteContactId = ko.observable(null);
        this.deleteContactName = ko.observable('');
        this.contactBlockId = ko.observable(null);
        this.contactBlockName = ko.observable('');
        this.searchname = ko.observable('');
        this.countContacts = ko.observable(0);
        this.tab = ko.observable('all');
        this.offset = ko.observable(0);
        this.limit = 12;

        if (Request.params.nickname !== undefined) this.getProfileByNickname(Request.params.nickname);

        this.getMyContacts();

        this.tab.subscribe(() => {
            this.offset(0);
            document.querySelector('.tyn-aside-body').scrollTop = 0;
            this.getRequestByTab();
        });

        document.querySelector('.tyn-aside-body').addEventListener('scroll', (e) => {
            if (Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) {
                this.offset(this.offset() + this.limit);
                this.getRequestByTab();
            }
        });

        this.Store.subscribe(() => {
           if (Store.getState().contactHandler.contacts.length > 0) {
               Store.getState().contactHandler.contacts.forEach(item => {
                   if (!this.isExist(item.id, 'contactList')) {
                       this.contactList.unshift(ko.mapping.fromJS({...item, isBlocked: false, isFavorite: false }));
                   }
               });
           }
            if (Store.getState().handler.name === 'add_to_black_list' && !this.isExist(Store.getState().handler.params.id, 'blackList')) {
                this.tab() === 'blocked' ? this.countContacts(this.countContacts() + 1) : null;
                this.blackList.push(ko.mapping.fromJS({...Store.getState().handler.params.data, isBlocked: false, isFavorite: false }));
            }
            if (Store.getState().handler.name === 'remove_from_black_list') {
                this.tab() === 'blocked' ? this.countContacts(this.countContacts() - 1) : null;
                this.blackList.remove(blacklist => blacklist.id() === Store.getState().handler.params.id);
            }
        });
    }

    async deviceId() {
        let deviceId = 'default';
        if (!!navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach(device => {
                if (device.kind === 'audioinput' && device.label === 'Speakerphone') {
                    deviceId = device.deviceId;
                }
            });
        }
        return deviceId;
    }

    async getStream(){
        return this.deviceId().then(deviceId=>navigator.mediaDevices.getUserMedia({
            audio: { useDtx: true, autoGainControl: true, noiseSuppression: true, echoCancellation: true, deviceId: { ideal: deviceId } },
            video: false
        }));
    }

    async sendStream(stream){
       return  this.Server.sendTrack(stream.getTracks()[0]);
    }


    Audio( payload ) {
        Audio.dispatch({ type: 'audio/set', payload });
    }

    Video( payload ) {
        Video.dispatch({ type: 'video/set', payload });
    }

    showToast(message, type) {
        Toastify({
            text: message,
            duration: 3000,
            gravity: "center",
            position: "center",
            style: {
                background: type === "success" ? "info" : "red"
            },
            close: true,
        }).showToast();
    }

    getRequestByTab() {
        switch (this.tab()) {
            case 'blocked' : this.getMyBlackList();
                break;
            case 'favorites': this.getMyFavoriteContacts();
                break;
            default: this.getMyContacts();
        }
    }

    updateField(contact, newVal, ...fieldsName) {
        fieldsName.forEach(name => {
            contact[name](newVal[name]);
        });
    }

    getListData(listType, route) {
        this.Server.Request(route, {limit: this.limit, offset: this.offset()})
            .then(res => {
                const response = JSON.parse(res);
                response.users.forEach((id, index) => {
                    if (this.isExist(id, listType) ) return false;
                    let contact = ko.mapping.fromJS({
                        name: '',
                        surname: '',
                        nickname: '',
                        avatar: '',
                        isBlocked: response.isBlocked ? response.isBlocked[index][id] : false,
                        isFavorite: response.isFavorite ? response.isFavorite[index][id] : false,
                        authid: false,
                        gender: '',
                        id,
                        bg: ''
                    });

                    this[listType].unshift(contact);

                    this.Server.Subscribe('user_profile', id).then(profile => {
                        this.updateField(contact, profile.get(), 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg', 'gender');
                        profile.on('update', (newVal) => {
                            this.updateField(contact, newVal, 'name', 'surname', 'nickname', 'authid', 'avatar', 'bg', 'gender');
                        });
                    });
                });
                this.countContacts(response.count);
            }).catch((e) => console.log(e));
    }

    getMyFavoriteContacts() {
        this.getListData('listOfFavoriteContacts', 'my_favorite_contacts');
    }

    getMyBlackList() {
        this.getListData('blackList', 'my_black_list');
    }

    getMyContacts() {
        this.getListData('contactList', 'my_contacts');
    }

    isExist(id, obsVar) {
        return ko.utils.arrayFilter(this[obsVar](), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    // deleteContact() {
    //     return this.Server.Request('delete_contact', {contact_user_id: this.deletedObj()})
    //         .then(() => {
    //             this.contactList.remove( (item) =>{
    //                 return Number(item.id()) === Number(this.deletedObj());
    //             });
    //         })
    //         .catch((e) => console.error(e));
    // }

    deleteContact() {
        return this.Server.Request('delete_contact', {contact_user_id: this.deletedObj()})
            .then(() => {
                this.contactList.remove((item) => {
                    return Number(item.id()) === Number(this.deletedObj());
                });
            })
            .catch((e) => console.error(e));
    }

    getProfileByNickname(nickname) {
        document.getElementById('tynMain').classList.add('main-shown');

        setTimeout(() => {
            this.Store.dispatch({type: 'handler/set', payload: {name: 'search_profile',params: {nickname}}});
        }, 0);
    }

    getProfileById(id) {
        this.currentId(id);
        setTimeout(() => {
            this.Store.dispatch({type: 'handler/set', payload: {name: 'search_profile',params: {id}}});
        },0);
        document.getElementById('tynMain').classList.add('main-shown');
    }

    handleServerResponse(promise, successCallback) {
        promise
            .then(res => {
                res = JSON.parse(res);
                const message = this.i18next.t(res.message);
                const type = res.success ? "success" : "error";
                this.showToast(message, type);

                if (res.success) {
                    successCallback();
                }
            })
            .catch(e => {
                console.error(e);
                this.showToast(this.i18next.t('An error occurred'), 'error');
            });
    }

    addToFavoriteContact(favorite_id) {
        const request = this.Server.Request('add_to_favorite_contacts', {favorite_id});
        this.handleServerResponse(request, () => {
            this.changeFlagItem('id', 'isFavorite', favorite_id);
        });
    }

    addToBlackList(block_id) {
        const request = this.Server.Request('add_to_black_list', {block_id});
        this.handleServerResponse(request, () => {
            this.changeFlagItem('id', 'isBlocked', block_id);
        });
    }

    removeFromFavoriteContact(favorite_id){
        this.Server.Request('delete_from_favorite_contacts', {favorite_id})
            .then(() => {
                this.listOfFavoriteContacts.remove( (item) =>{
                    return Number(item.id()) === Number(favorite_id);
                });
                this.changeFlagItem('id', 'isFavorite', favorite_id);
            })
            .catch((e) => console.error(e));
    }

    showBlockModal(block_id, contact_name) {
        this.contactBlockId(block_id);
        this.contactBlockName(contact_name);
    }

    confirmBlockContact() {
        this.addToBlackList(this.contactBlockId(), );
    }

    showDeleteModal(contact_id, contact_name) {
        this.deleteContactId(contact_id);
        this.deleteContactName(contact_name);
    }

    confirmDeleteContact() {
        this.deletedObj(this.deleteContactId());
        this.deleteContact();
    }

    unblock(block_id) {
        this.Server.Request('delete_from_black_list', {block_id})
            .then(() => {
                this.blackList.remove( (item) =>{
                    return Number(item.id()) === Number(block_id);
                });
                this.changeFlagItem('id', 'isBlocked', block_id);
            })
            .catch((e) => console.log(e));
    }

    changeFlagItem(field_name, flag_name, id) {
        ko.utils.arrayMap(this.contactList(), (contact) => {
            if (contact[field_name]() === id) {
                contact[flag_name](!contact[flag_name]());
            }
            return contact;
        });
    }

    openChatDialog(id) {
        this.currentId(id);
        this.Router.navigate('/dialog/' + id);
    }

    searchContacts() {
        return {
            input: (data, event) => {
                if (event.target.value.length > 0) {
                    this.searchTab(true);
                    this.debounce(400, () => {
                        this.Server.Request('get_search_contacts', {search_text: event.target.value})
                            .then(res => {
                                res = JSON.parse(res);
                                if (res.success) {
                                    this.seachList([]);
                                    res.profiles.forEach(id => {
                                        this.Server.Subscribe('user_profile', id).then(Subscribe => {
                                            this.seachList.push(ko.mapping.fromJS(Subscribe.get()));
                                        });
                                    });
                                }
                            }).catch(e => console.log(e));
                    });
                } else {
                    this.searchTab(false);
                    this.seachList([]);
                }
            }
        };
    }
}
