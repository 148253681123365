import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import {EmojiButton} from '@joeattardi/emoji-button';
import Toastify from "toastify-js";
import GLightbox from "glightbox";
import Audio from '/redux/audio';
import Video from '/redux/video';

export default class chat_chat  extends Handler {
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });
        this.getInput = ko.observable('');
        this.chatItems = ko.observableArray([]);
        this.offset = ko.observable(0);
        this.limit = 20;
        this.all_messages_length = ko.observable(0);
        this.dialogUser = ko.observable('');
        this.chatInput = document.querySelector('#tynChatInput');
        this.dialog_id = ko.observable(0);
        this.messageId = ko.observable(0);
        this.doubleId = ko.observable(0);
        this.isShowAllEmoji = ko.observable(false);
        this.showMore = ko.observable(false);
        this.showLastMore = ko.observable(false);

        this.audioRemoteMuted = ko.observable(false);
        this.bg = ko.observable(null);
        this.loadBg = ko.observable(0);
        this.isShowSearch = ko.observable(false);
        this.searchMessageText = ko.observable("");
        this.userRecepient = ko.observable(0);
        this.seachMessagesList = ko.observableArray([]);
        this.searchMessageIndex = ko.observable(-1);
        this.visibleVoiceBtn = ko.observable(true);

        this.isShowSearch.subscribe((isShow) => {
            if (!isShow) this.searchMessageText("");
        });

        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            this.visibleVoiceBtn(false);
        }

        this.recognition = null;
        this.initializeSpeechRecognition();

        this.chatInput.addEventListener("keypress", (event) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                if (this.messageId() > 0) {
                    this.theEditMessage();
                } else {
                    this.sendMessage();
                }
            }
        });



        this.unsubscribe = Store.subscribe(() => {
            let state = Store.getState();

            if (state.handler.name === 'initial' ) {
                this.initNewDialog();
                return;
            }
            if(state.openDialogueHandler.id) {
                this.initNewDialog();
            }


            if (state.handler.name === 'delete_message' && this.checkDenyDialog() ) {
                if(this.isExist(state.handler.params.id)) {
                    this.chatItems.remove(message => message.id() === state.handler.params.id);
                }
            }

            if (state.handler.name === 'message' && this.checkDenyDialog()) {

                if(state.handler.params.data.type === 'message'){
                    if(this.isExist(state.handler.params.id)) {
                        ko.utils.arrayMap(this.chatItems(), (message) => {
                            if (message.id() === state.handler.params.id) {
                                message.message_text(state.handler.params.data.message_text);
                            }
                            return message;
                        });
                    } else {
                        if (!this.showLastMore()) {
                            let message = state.handler.params.data;
                            this.chatItems.push(ko.mapping.fromJS({...message, reactions: []}));
                        }
                        this.updateStatus();

                        this.scrollToTop();
                        this.readAllMessages();
                    }
                }

                if(state.handler.params.data.type === 'audio'){
                    if(this.isExist(state.handler.params.id)) {
                        ko.utils.arrayMap(this.chatItems(), (message) => {
                            if (message.id() === Store.getState().handler.params.id) {
                                message.message_text(ko.mapping.fromJS(JSON.parse(Store.getState().handler.params.data.message_text))());
                            }
                            return message;
                        });
                    }
                }
            }

            if (state.handler.name === 'contact' && this.checkDenyDialog() && !this.showLastMore()) {
                if(!this.isExist(Store.getState().handler.params.id)) {

                    const message_data = JSON.parse(state.handler.params.data.message_text);
                    let data = state.handler.params.data;

                    const contactProfile = ko.mapping.fromJS({
                        avatar: '',
                        name: '',
                        surname: '',
                        user_id: message_data.id,
                        link: message_data.link,
                        nickname: ''
                    });

                    this.Server.Subscribe('user_profile', message_data.id).then(Subscribe => {
                        this.updateField(contactProfile, Subscribe.get(), 'name', 'surname', 'avatar', 'nickname');
                        Subscribe.on('update', (newVal) => {
                            this.updateField(contactProfile, newVal, 'name', 'surname', 'avatar', 'nickname');
                        });
                    });

                    data = {...data, ...contactProfile};

                    this.chatItems.push(ko.mapping.fromJS(data));

                    this.scrollToTop();
                }
            }

            if ((state.handler.name === 'audio' || state.handler.name === 'video') && this.checkDenyDialog() && !this.showLastMore()) {
                let newMessage = ko.mapping.fromJS({
                    ...state.handler.params.data,
                    id: state.handler.params.data.id,
                    message_text: JSON.parse(state.handler.params.data.message_text),
                    loadBg: 0
                });
                this.chatItems.push(newMessage);
                this.updateStatus();
                this.scrollToTop();
            }

            if (state.handler.name === 'reaction') {

                const {action, oldName, name} = state.handler.params.data;

                this.updateReaction(action, name, oldName, state.handler.params.id);
            }

            if (state.handler.name === 'files' && this.checkDenyDialog() && !this.showLastMore()) {

                let messageData = {};

                const message_data = JSON.parse(state.handler.params.data.message_text);

                for (let key in state.handler.params.data) {
                    messageData[key] = state.handler.params.data[key];
                }

                messageData.message_text = [];

                message_data.forEach(item => {

                    const fileData = ko.mapping.fromJS({file_name: 'file.php', file_extension: 'php', file_size: 0});

                    Server.links(item.object_id).then((file) => {
                        fileData.file_name(file.name);
                        fileData.file_extension(file.name.split('.')[1]);
                        fileData.file_size(this.niceBytes(file.size));
                    }).catch(e=>console.log(e));

                    messageData.message_text.push(fileData);

                });

                this.chatItems.push(ko.mapping.fromJS(messageData));

                this.scrollToTop();
            }

            if (['images', 'video_file'].includes(state.handler.name) && this.checkDenyDialog() && !this.showLastMore()) {
                let newMessage = ko.mapping.fromJS({
                    ...state.handler.params.data,
                    id: state.handler.params.data.id,
                    message_text: JSON.parse(state.handler.params.data.message_text),
                    loadBg: 0
                });
                newMessage.message_text().forEach(item => {
                    this.Server.Subscribe('user_files', item.id())
                        .then(file => {
                            if ( Math.round(file.get('offset') / (file.get('size') / 100)) === 100) {
                                newMessage.loadBg(0);
                            }
                            if (file.get('object_id')) {
                                item.object_id(file.get('object_id'));
                                newMessage.loadBg(0);
                            }
                            file.on('update', (newValue) => {
                                newValue.size = Number(newValue.size);
                                if (Math.round(newValue.offset / (newValue.size / 100)) === 100) {
                                    newMessage.loadBg(0);
                                    if (newValue.object_id) item.object_id(newValue.object_id);
                                } else {
                                    if(Number(newMessage.loadBg())<Math.round(newValue.offset / (newValue.size / 100)) && !item.object_id()){
                                        newMessage.loadBg(Math.round(newValue.offset / (newValue.size / 100)));
                                    }
                                }
                            });
                        });
                });
                this.chatItems.push(newMessage);
                this.updateStatus();
                this.scrollToTop();
            }
        });

        this.isShowAllEmoji.subscribe(() => {
            document.querySelectorAll('.btn-dropdown-trigger-close').forEach(item => {
                item.addEventListener('show.bs.dropdown', () => {this.isShowAllEmoji(false);});
                return item.removeEventListener('show.bs.dropdown', () => {this.isShowAllEmoji(false);});
            });
        });

        const picker = new EmojiButton({
            rootElement: document.querySelector('.emoji-picker-container'),
            showPreview: false,
            position: 'top-start',
            zIndex: 999999,
            i18n: {
                search: i18next.t('Search emojis...'),
                categories: {
                    recents: i18next.t('Recent Emojis'),
                    smileys: i18next.t('Smileys & Emotion'),
                    people: i18next.t('People & Body'),
                    animals: i18next.t('Animals & Nature'),
                    food: i18next.t('Food & Drink'),
                    activities: i18next.t('Activities'),
                    travel: i18next.t('Travel & Places'),
                    objects: i18next.t('Objects'),
                    symbols: i18next.t('Symbols'),
                    flags: i18next.t('Flags'),
                    custom: i18next.t('Custom')
                },
                notFound: 'No emojis found'
            },
            theme: Store.getState().dark_mode.value,
            showVariants: true,
            style: "light"
            // showSearch: false
        });

        const trigger = document.querySelector('.picker-trigger');

        picker.on('emoji', selection => {
            setTimeout(() => {
                this.getInput(this.pasteEmojiToField(document.querySelector('.tyn-chat-form-input'), selection.emoji));
            },5);
        });

        trigger.addEventListener('click', () => {
            picker.togglePicker(trigger);
        });

        this.initNewDialog();

        document.querySelector('.tyn-chat-body').addEventListener('scroll', () => {
            if (this.isElementInViewport(document.querySelector('.more-container')) && this.showMore()) {
                this.debounce(100, () => this.getMoreMessages());
            }
        });
        if (this.isElementInViewport(document.querySelector('.more-container')) && this.showMore()) {
            this.debounce(100, () => this.getMoreMessages());
        }
    }

    isElementInViewport (el) {
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    initNewDialog() {
        if (this.dialog_id() !== Number(this.Store.getState().openDialogueHandler.id)) {
            this.chatItems([]);
            this.dialog_id(Number(this.Store.getState().openDialogueHandler.id));
            this.getRecipient();
            this.userSubscribe();
        }
    }

    userSubscribe() {
        if (this.dialog_id() > 0) {
            this.Server.Subscribe('user_profile',this.dialog_id()).then(Subscribe=>{
                let User = ko.mapping.fromJS(Subscribe.get());
                this.dialogUser(User);
                Subscribe.on('update', (newVal) => {
                    this.updateField(User, newVal, 'avatar', 'name', 'surname', 'bg');
                });
            });
        }
    }

    // getUserDialog(id) {
    //     this.Server.Subscribe('user_profile',id).then(Subscribe=>{
    //         let User = ko.mapping.fromJS(Subscribe.get());
    //         this.dialogUser(User);
    //         Subscribe.on('update', (newVal) => {
    //             User.avatar(newVal.avatar);
    //             User.name(newVal.name);
    //             User.surname(newVal.surname);
    //             User.bg(newVal.bg);
    //         });
    //     });
    // }

    updateStatus() {
        let sendingArr = [];
        ko.utils.arrayMap(this.chatItems(), (message) => {
            if (message.status() === 0) {
                sendingArr.push(message.id());
                message.status(1);
            }
        });

        if (!sendingArr.length) return true;

        this.Server
            .Request('update_statuses', {
                value: {status: 1, arr:sendingArr, userid:this.dialog_id()}
            }).then(res=>{
            let result = JSON.parse(res);
            if (result) {
                return true;
                //result = JSON.parse(result);
                //this.Server.Request('update_notify',{userid:this.dialog_id()}).then((result)=>{
                    //console.log(result);
                //}).catch(e => console.log(e));
            }
        }).catch(e => console.log(e));
    }

    initializeSpeechRecognition() {
        if ('webkitSpeechRecognition' in window) {
            this.recognition = new webkitSpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = false;
            this.recognition.lang = 'ru-RU';

            let silenceTimeout;

            const resetSilenceTimeout = () => {
                if (silenceTimeout) {
                    clearTimeout(silenceTimeout);
                }
                silenceTimeout = setTimeout(() => {
                    this.stopVoiceInput();
                }, 3000);
            };

            this.recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                this.getInput(this.getInput() + transcript);
                resetSilenceTimeout();
            };

            this.recognition.onend = () => {
                document.querySelector('.bi-mic').classList.remove('recording');
            };

            this.recognition.onerror = (e) => {
                Toastify({
                    text: this.i18next.t('Speech recognition error detected'),
                    duration: 4000,
                    gravity: "top",
                    position: "center",
                    style: {
                        background: "warning"
                    },
                    close: true,
                }).showToast();
                console.log(e.error);
                document.querySelector('.bi-mic').classList.remove('recording');
            };
        }
    }

    startVoiceInput() {
        navigator.permissions.query({ name: 'microphone' })
            .then((permissionStatus) => {
                if (permissionStatus.state === 'granted') {
                    this.startRecognition();
                } else {
                    this.requestMicrophoneAccess()
                        .then(() => {
                            Toastify({
                                text: this.i18next.t('Microphone access granted. Please click the button again to start voice input.'),
                                duration: 4000,
                                gravity: "top",
                                position: "center",
                                style: {
                                    background: "info"
                                },
                                close: true,
                            }).showToast();
                        })
                        .catch((error) => {
                            Toastify({
                                text: this.i18next.t('Microphone access denied:', error),
                                duration: 4000,
                                gravity: "top",
                                position: "center",
                                style: {
                                    background: "warning"
                                },
                                close: true,
                            }).showToast();
                            console.error('Microphone access denied:', error);
                        });
                }
            })
            .catch((error) => {
                Toastify({
                    text: this.i18next.t('Permission check failed:', error),
                    duration: 4000,
                    gravity: "top",
                    position: "center",
                    style: {
                        background: "warning"
                    },
                    close: true,
                }).showToast();
                console.error('Permission check failed:', error);
            });
    }

    requestMicrophoneAccess() {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    stream.getTracks().forEach(track => track.stop());
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    startRecognition() {
        if (this.recognition) {
            this.recognition.start();
            document.querySelector('.bi-mic').classList.add('recording');
        }
    }

    stopVoiceInput() {
        if (this.recognition) {
            this.recognition.stop();
            document.querySelector('.bi-mic').classList.remove('recording');
        }
    }

    confirmDeleteModal(data){
        const {id, recipient_id, sender_id} = data;
        this.Store.dispatch({
            type: 'modal/setDataModal', payload: {name: 'deleteModalMessage', data: {id, recipient_id, sender_id}}
        });
        new bootstrap.Modal(document.getElementById('deleteForMe')).show();
    }

    modalConfirmationDeleteEveryone(data){
        const {id, id2id, recipient_id, sender_id} = data;
        this.Store.dispatch({
            type: 'modal/setDataModal', payload: {name: 'deleteMessageForEveryone', data: {id, id2id, recipient_id, sender_id}}
        });
        new bootstrap.Modal(document.getElementById('deleteForEveryone')).show();
    }

    saveReaction(name, data) {
        this.Server.Request('save_reaction', {name, message_id: data.id(), toId: data.id2id(), recipient_id: data.recipient_id(), sender_id: data.sender_id()})
            .then((res) => {
                let result = JSON.parse(res);
                if (result.success) this.updateReaction(result.action, name, result.oldName, data.id());
            })
            .catch((e) => console.log(e));
    }

    updateReaction(action, name, oldName, id) {
        ko.utils.arrayMap(this.chatItems(), (message) => {
            if (message.id() === id) {
                switch (action) {
                    case 'create': message.reactions.push(name);
                        break;
                    case 'update': message.reactions.replace(oldName, name);
                        break;
                    default: message.reactions.remove(item => item === oldName);
                }
            }
            return message;
        });
    }

    async deviceId() {
        let deviceId = 'default';
        if (!!navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach(device => {
                if (device.kind === 'audioinput' && device.label === 'Speakerphone') {
                    deviceId = device.deviceId;
                }
            });
        }
        return deviceId;
    }

    async getStream(){
        return this.deviceId().then(deviceId=>navigator.mediaDevices.getUserMedia({
            audio: { useDtx: true, autoGainControl: true, noiseSuppression: true, echoCancellation: true, deviceId: { ideal: deviceId } },
            video: false
        }));
    }

    async sendStream(stream){
        return  this.Server.sendTrack(stream.getTracks()[0]);
    }








    checkDenyDialog() {
        let r = Number(this.Store.getState().handler.params.data.recipient_id),
            s = Number(this.Store.getState().handler.params.data.sender_id),
            d = this.dialog_id();
        return r === d || s === d;
    }

    pasteEmojiToField(html, selectPastedContent) {
        const startPos =  html.selectionStart;
        const endPos =  html.selectionEnd;
        html.focus();
        html.setRangeText(selectPastedContent, startPos, endPos);
        html.setSelectionRange(startPos + 2, endPos + 2);
        return html.value;
    }

    async sendVideo(model, { target }) {
        return this.sendFile(target.files,'video_file');
    }

    async sendPhoto(model, { target }) {
        return this.sendFile(target.files,'images');
    }

    async sendDoc(model, { target }) {
        return this.sendFile(target.files,'files');
    }

    async loadComplete() {}

    async sendFile( filesUpload , type ) {
        let images = [];
        this.Server.sendFiles( filesUpload,1000)
            .then(Files=> {
                const itemId = this.messageId() ? this.messageId() : this.dialog_id();
                Files.forEach(file => {
                    images.push({"id": ko.observable(file.get('id')), "object_id": ko.observable(file.get('object_id')), "percent": ko.observable(0)});
                    let foundItem = images.find((element) => element.id() === file.get('id'));
                    file.on('update', (newValue) => {
                        foundItem.percent(Math.round(newValue.offset / (newValue.size / 100)));
                        if(newValue.object_id) {
                            foundItem.object_id(newValue.object_id);
                        }
                    });
                });

                this.Server.Request('send_message', {   value: JSON.stringify(ko.mapping.toJS(images)), user_id: itemId,  type }).then(res => {
                    let result = JSON.parse(res);
                    result.message.message_text = JSON.parse(result.message.message_text);
                    let newMessage = ko.mapping.fromJS(result.message);
                    Files.forEach(file => {
                        let foundIMG = images.find((element) => element.id() === file.get('id'));
                        foundIMG.object_id.subscribe(()=>{
                            this.Server.Request('edit_message', {
                                value: JSON.stringify(ko.mapping.toJS(images)), ...ko.mapping.toJS(newMessage),
                                user_id: itemId,
                                id2id: result.message.id2id
                            });
                        });

                        file.on('update', (newValue) => {
                            newValue.size = Number(newValue.size);
                            if(newValue.object_id){
                                const found = images.find((element) => element.id() === file.get('id'));
                                found.object_id(newValue.object_id);
                            }
                            if (Math.round(newValue.offset / (newValue.size / 100)) === 100) {
                                const found = images.find((element) => element.id() === file.get('id'));
                                found.object_id(newValue.object_id);
                            }
                        });
                    });


                }).catch(e => console.log(e));
            })
            .catch((e) => {
                console.log(e);
            });
    }

    isExist(id) {
        return ko.utils.arrayFilter(this.chatItems(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    debounce(delay, fun) {
        clearTimeout(this.timer);
        this.timer = setTimeout(fun, delay);
    }

    getRecipient() {
        if (this.dialog_id()) {
            this.getListDialogs();
            // this.getUserDialog(this.dialog_id());
        }
    }

    backRoad() {
        document.getElementById('tynMain').classList.remove('main-shown');
        return this.Router.navigate('/');
    }

    theEditMessage() {
        if(!this.getInput()){
            console.log('Fields cannot be empty');
            return false;
        }
        this.Server
            .Request('update_message', {
                value: this.getInput(), id: this.messageId(), id2id : this.doubleId(),user_id: this.dialog_id()
            }).then(res=>{
            let result = JSON.parse(res);
            if (result) {
                this.chatInput.style.height = '40px';
                this.getInput('');
                this.messageId(0);
            }
        }).catch(e => console.log(e));
    }

    readAllMessages() {
        if (this.showLastMore()) {
            this.Server.Request('read_all_messages', { userid:this.dialog_id() })
                .then(res => {
                    res = JSON.parse(res);
                    if (res.success) {
                        this.chatItems([]);
                        this.getListDialogs();
                    }
                })
                .catch(e=>console.log(e));
        }
    }

    sendMessage() {
        const messageText = this.getInput().trim();

        if (messageText.length === 0)  return;

        this.Server
            .Request('send_message', {
                value: messageText,
                user_id: this.dialog_id(),
                type: 'message'
            }).then(res => {
            let result = JSON.parse(res);
            if (result) {

                if (['images', 'audio', 'video_file', 'contact', 'files', 'video'].includes(result.type)) result.message_text = JSON.parse(result.message.message_text);

                this.chatInput.style.height = '40px';
                this.getInput('');
                this.scrollToTop();
            }
        }).catch(e => console.log(e));
    }

    getListDialogs(last_message_id, isPrev = true, searchMessageId = null) {
        this.Server.Request('get_messages_by_user', {userid:this.dialog_id(), limit: this.limit, status:'active', searchID: last_message_id, isPrev})
            .then(res => {
                const response = JSON.parse(res);

                if (response.success) {

                    this.showMore(true);

                    this.showLastMore(true);

                    response.messages.reverse().forEach(message => {

                        if (this.isExist(message.id)) return false;

                        if (['images', 'audio', 'video_file', 'contact', 'files'].includes(message.type)) message.message_text = JSON.parse(message.message_text);

                        if (message.type === 'contact') {

                            const contactProfile = ko.mapping.fromJS({avatar: '', name: '', surname: '', user_id: 0, nickname: ''});

                            contactProfile.user_id(message.message_text.id);
                            this.Server.Subscribe('user_profile', message.message_text.id).then(Subscribe => {
                                this.updateField(contactProfile, Subscribe.get(), 'name', 'surname', 'avatar', 'nickname');
                                Subscribe.on('update', (newVal) => {
                                    this.updateField(contactProfile, newVal, 'name', 'surname', 'avatar', 'nickname');
                                });
                            });
                            message = {...message, ...contactProfile};
                        } else if(message.type === 'files') {

                            message.message_text.forEach((item, index) => {
                                const fileData = ko.mapping.fromJS({file_name: '', file_extension: '', file_size: 0});

                                this.Server.links(item.object_id).then((file) => {
                                    fileData.file_name(file.name);
                                    fileData.file_extension(file.name.split('.')[1]);
                                    fileData.file_size(this.niceBytes(file.size));
                                }).catch(e=>console.log(e));
                                message.message_text[index] =fileData;
                            });

                        }

                        if (isPrev) {
                            this.chatItems.unshift(ko.mapping.fromJS(message));
                        } else {
                            this.chatItems.push(ko.mapping.fromJS(message));
                        }

                    });

                    ko.utils.arrayMap(this.chatItems(), (message) => {
                        if (message.id() === response.minID) this.showMore(false);
                        if (message.id() === response.maxID) this.showLastMore(false);
                    });

                    if (!!searchMessageId) {
                        const elem = document.querySelector(`.tyn-reply-item[data-id="${last_message_id}"]`);
                        elem.scrollIntoView({ block: "center", behavior: "smooth" });
                        elem.classList.add('choose-search-message-focus');

                        setTimeout(() =>{
                            elem.classList.remove('choose-search-message-focus');
                        },800);
                    }

                    GLightbox({touchNavigation: true, loop: true, autoplayVideos: true});

                    this.updateStatus();
                }
            }).catch((e) => console.log('getListDialogs ', e));
    }

    niceBytes(x){

        const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        let l = 0, n = parseInt(x, 10) || 0;

        while(n >= 1024 && ++l){
            n = n/1024;
        }

        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    getMoreMessages() {
        this.showMore(false);
        this.offset(this.chatItems().length);
        this.getListDialogs(this.chatItems()[0].id());
    }

    getLastMoreMessages() {
        this.showLastMore(false);
        this.getListDialogs(this.chatItems().pop().id(), false);
    }

    scrollToTop() {
        document.querySelector('.chat-list').scrollIntoView({block: "end"});
    }

    updateMessage(value) {
        this.editMessage.message_text(value);
    }

    cancelEdit() {
        this.editMessage = {};
        this.getInput('');
        this.messageId(0);
    }
    addToEditMessage(data) {
        this.editMessage = data;
        this.getInput(data.message_text());
        this.messageId(data.id());
        this.doubleId(data.id2id());
    }

    Audio( payload ) {
        Audio.dispatch({ type: 'audio/set', payload });
    }

    Video( payload ) {
        Video.dispatch({ type: 'video/set', payload });
    }

    chooseSearchMessage(id) {
        this.hideOrShowSearchMessageContainer('hide');

        ko.utils.arrayMap(this.seachMessagesList(), (message, index) => {
            if (message.id() === id) {
                this.getListDialogs(this.seachMessagesList()[index].id(), true, this.seachMessagesList()[index].id());
                this.chatItems([]);
            }
            return message;
        });
    }

    nextChooseMessage() {
        if (this.searchMessageIndex() < this.seachMessagesList().length - 1)
            this.searchMessageIndex(this.searchMessageIndex() + 1);
    }

    prevChooseMessage() {
        this.searchMessageIndex(this.searchMessageIndex() > 0 ? this.searchMessageIndex() - 1 : 0);
    }

    searchMessages() {
        return {
            input: (data, { target }) => {
                if (target.value !== '') {
                    this.debounce(400, () => {
                        this.Server.Request('get_messages_by_text', {searchText: target.value, recipient_id: this.dialog_id()})
                            .then(response => {
                                    this.seachMessagesList([]);
                                    response = JSON.parse(response);

                                    if (response.success) {

                                        response.messages.forEach(message => {

                                            let messageItem = ko.mapping.fromJS({
                                                avatar: '',
                                                id: message.id,
                                                message_text: message.message_text,
                                                recipient_id: message.recipient_id,
                                                type: message.type,
                                                name: '',
                                                surname: ''
                                            });

                                            this.seachMessagesList.push(messageItem);

                                            this.Server.Subscribe('user_profile', message.sender_id).then(profile => {
                                                this.updateField(messageItem, profile.get(), 'avatar', 'name', 'surname');
                                                profile.on('update', (newValue) => {
                                                    this.updateField(messageItem, newValue, 'avatar', 'name', 'surname');
                                                });
                                            });

                                        });
                                    }
                                }
                            );
                    });
                } else {
                    this.seachMessagesList([]);
                }
            },
            focus: () => {
                this.hideOrShowSearchMessageContainer('show');
            },
            blur: () => {
                this.hideOrShowSearchMessageContainer('hide');
            }
        };
    }

    hideOrShowSearchMessageContainer(action) {
        let container = document.querySelector('.search-messages-container');
        container.style.opacity = action === 'show' ? 1 : 0;
        setTimeout(()=>{
            container.style.display = action === 'show' ? 'block' : 'none';
        },500);
    }

    updateField(variable, newVal, ...fieldsName) {
        fieldsName.forEach(name => {
            variable[name](newVal[name]);
        });
    }
}
