import { configureStore, createSlice } from '@reduxjs/toolkit';
export default configureStore({ reducer: {  caller:createSlice({
            name: 'caller',
            initialState: { audio : null, video : null, userid : null },
            reducers: {
                set: (state, action) => {
                    state.audio = action.payload.audio;
                    state.video = action.payload.video;
                    state.userid = action.payload.userid;
                },
                reset: (state) => {
                    state.audio = null;
                    state.video = null;
                    state.userid = null;
                }
            }
        }).reducer } });