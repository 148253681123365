import ko from 'knockout';
import 'knockout-mapping';
import Handler from "engine/Handler";
import Audio from '/redux/audio';
import Video from '/redux/video';

export default class user_profile extends Handler{
    constructor({ Store, Router, Server, i18next }) {
        super({ Store, Router, Server, i18next });

        this.profile = ko.mapping.fromJS({
            about_me: "",
            address: null,
            authid: 0,
            avatar: '',
            bg: '',
            birthday: '',
            gender: '',
            id: 0,
            lang: '',
            name: "",
            nickname: "",
            surname: "",
            show_address: true,
            show_birthday: true,
            show_me_in_search: true,
            show_name: true,
            show_number: true,
            show_surname: true,
            isBlackList: false,
            isContact: false
        });

        this.profilePhone = ko.observable('');

        this.contacts = ko.observableArray([]);

        this.offset = ko.observable(0);

        this.limit = 6;

        this.not_found_profile = ko.observable(false);

        this.route_nickname = ko.observable('');

        this.count_contacts = ko.observable(0);

        Store.subscribe(()=> {
            if (Store.getState().handler.name === 'search_profile') {
                if (!!Store.getState().handler.params.id  && Store.getState().handler.params.id !== this.profile.id()) {
                    this.updateProfile(Store.getState().handler.params.id);
                } else if(!!Store.getState().handler.params.nickname && Store.getState().handler.params.nickname !== this.profile.nickname()) {
                    this.updateProfile(Store.getState().handler.params.nickname);
                }

                this.route_nickname('s');
            }
        });
    }

    showProfilePhone(){
        const cleaned = ('' + this.profilePhone()).replace(/\D/g, '');
        const match = cleaned.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
        if (match) {
            return `+7 (${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
        } else {
            return cleaned;
        }
    }

    backRoad() {
        document.getElementById('tynMain').classList.remove('main-shown');
    }

    getMoreContacts() {
       this.offset(this.offset() + this.limit);
       this.getProfileContacts(this.profile.authid());
    }

    getProfileContacts(id) {
        this.Server.Request('mutual_contacts', {limit: this.limit, offset: this.offset(), userid: id})
            .then(res => {
                const response = JSON.parse(res);
                this.count_contacts(response.count);
                response.users.forEach(id => {
                    if (this.isExist(id)) return false;
                    this.Server.Subscribe('user_profile',id).then(person=> {
                        this.contacts.push(ko.mapping.fromJS(person.get()));
                        person.on('update',(NewValue)=>{
                            ko.utils.arrayMap(this.contacts(), (contact) => {
                                if (contact.id() === NewValue.id) this.updateField(contact, NewValue, 'avatar', 'id', 'name', 'nickname', 'surname');
                                return contact;
                            });
                        });
                    });

                });
            }).catch((e)=>console.log(e));
    }

    updateProfile(nickname) {

        if (!!nickname) {

            this.offset(0);

            this.contacts.removeAll();

            const isNumber = /^\d+$/.test(nickname);

            this.Server.Request('profile_by_param', isNumber ? {id: nickname} : {nickname})
                .then(res => {
                    res = JSON.parse(res);
                    this.not_found_profile(res.success);

                    if (res.success) {

                        this.updateField(this.profile, res.profile, 'about_me', 'address', 'avatar', 'bg', 'birthday', 'gender', 'lang', 'name', 'nickname', 'surname');

                        this.Server.Subscribe('user_profile',res.profile.authid).then(profile=>{
                            profile.on('update', (newValue) => this.updateField(this.profile, newValue, 'about_me', 'address', 'avatar', 'bg', 'birthday', 'gender', 'lang', 'name', 'nickname', 'surname'));
                            this.updateField(this.profile, profile.get(), 'about_me', 'address', 'avatar', 'bg', 'birthday', 'gender', 'id', 'lang', 'name', 'nickname', 'surname');
                        });

                        this.Server.Subscribe('profile_settings',res.profile.authid).then(settings=>{
                            settings.on('update',(newValue) => this.updateField(this.profile, newValue, 'show_address', 'show_birthday', 'show_me_in_search', 'show_number', 'show_surname'));
                            this.updateField(this.profile, settings.get(), 'show_address', 'show_birthday', 'show_me_in_search', 'show_number', 'show_surname');
                        });

                        this.Server.Subscribe('phones', res.profile.authid).then(phone => {
                            this.profilePhone(phone.get().phone);
                            phone.on('update', (newVal) => {
                                this.profilePhone(newVal.phone);
                            });
                        });

                        this.getProfileContacts(res.profile.authid);

                        this.route_nickname(res.profile.nickname);

                        this.profile.isBlackList(res.isBlackList);
                        this.profile.isContact(res.isContact);
                    }
                });
        }
    }

    addUserSubscribe(id) {
        this.Server.Subscribe('user_profile',id).then(Subscribe=>{
            let user = Subscribe.get();
            this.Server.Subscribe('profile_settings',id).then(res=>{
                this.Store.dispatch({type: 'contacts/add', payload: {...user, ...res.get()}});
            });
        });
    }

    addNewContact(contact_user_id) {
        this.Server.Request('add_contact', {contact_user_id})
            .then(() => {
                this.profile.isContact(true);
                this.addUserSubscribe(contact_user_id);
            })
            .catch((e) => console.error(e));
    }
    isExist(id) {
        return ko.utils.arrayFilter(this.contacts(), (item) => {
            if (item.id() === id) return id;
        }).length >= 1;
    }

    parseDay(dateStr) {
        const moths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'August', 'September', 'October', 'November', 'December'];

        const date = new Date(dateStr);

        return `${moths[date.getMonth()]} ${date.getDay()}`;
    }

    updateField(variable, newVal, ...fieldsName) {
        fieldsName.forEach(name => {
            variable[name](newVal[name]);
        });
    }

    call( type ) {
        const { name, surname, bg, avatar, id, gender } = ko.toJS(this.profile);
        if(type === 'video'){
            Video.dispatch({ type: 'video/set' , payload: { name, surname, bg, avatar, id, gender } });
        }

        if (type === 'audio') {
            Audio.dispatch({ type: 'audio/set' , payload: { name, surname, bg, avatar, id, gender } });
        }
    }

    async sendStream(stream){
        return  this.Server.sendTrack(stream.getTracks()[0]);
    }

    async getStream(){
        return this.deviceId().then(deviceId=>navigator.mediaDevices.getUserMedia({
            audio: { useDtx: true, autoGainControl: true, noiseSuppression: true, echoCancellation: true, deviceId: { ideal: deviceId } },
            video: false
        }));
    }

    async deviceId() {
        let deviceId = 'default';
        if (!!navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            let devices = await navigator.mediaDevices.enumerateDevices();
            devices.forEach(device => {
                if (device.kind === 'audioinput' && device.label === 'Speakerphone') {
                    deviceId = device.deviceId;
                }
            });
        }
        return deviceId;
    }

    openChatDialog() {
        this.Router.navigate('/dialog/' + this.profile.id());
    }

    block() {
        this.Server.Request('add_to_black_list', {block_id: this.profile.id()}).catch((e) => console.log(e));
        this.Store.dispatch({type: 'handler/set', payload: {name: 'add_to_black_list',params: {id: this.profile.id(), data: ko.toJS(this.profile)}}});
        this.profile.isBlackList(true);
    }

    unblock() {
        this.Server.Request('delete_from_black_list', {block_id: this.profile.id()}).catch((e) => console.log(e));
        this.Store.dispatch({type: 'handler/set', payload: {name: 'remove_from_black_list',params: {id: this.profile.id()}}});
        this.profile.isBlackList(false);
    }

}
