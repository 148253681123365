import { configureStore, createSlice } from '@reduxjs/toolkit';
export default configureStore({ reducer : {  audio : createSlice({
            name: 'audio',
            initialState: { id: null, name:null, surname:null, bg:null, avatar:null, gender:null },
            reducers: {
                set: (state, action) => {
                    state.id = action.payload.id;
                    state.name = action.payload.name;
                    state.surname = action.payload.surname;
                    state.bg = action.payload.bg;
                    state.avatar = action.payload.avatar;
                    state.gender = action.payload.gender;
                },
                reset: (state) => {
                    state.id = false;
                    state.name = false;
                    state.surname = false;
                    state.bg = false;
                    state.avatar = false;
                    state.gender = false;
                }
            }
        }).reducer } });